import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { GasIcon } from 'components/AndyComponents/icons'
import GasSelectorModal from 'components/GasSelectorModal'
import React, { useContext } from 'react'
import { ArrowUpRight } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import { useIsMobile } from 'pages/Swap/SelectiveCharting'


const StyledSwapHeader = styled.div <{ isMobile?: boolean}>`
  padding: ${({ isMobile}) => (isMobile ? '20px 25px 10px 25px' : '25px 25px 15px 25px')};
  width: 100%;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg0};
  border-radius: 24px 24px 0px 0px;
`


export default function SwapSubHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
const theme = useContext(ThemeContext)
const [gasSettingsOpen, setGasSettingsOpen] = React.useState(false);
const openGasSettings = () => setGasSettingsOpen(true)
const closeGasSettings = () => setGasSettingsOpen(false)
const isMobile = useIsMobile()
  return (
    <StyledSwapHeader isMobile={isMobile}>
      <RowBetween>
        <RowFixed>
      <GasSelectorModal isOpen={gasSettingsOpen} onDismiss={closeGasSettings} />

               <small style={{ color: theme.text1, cursor: 'pointer', display: 'flex', marginBottom: 0, alignItems: 'center', justifyContent: 'flex-end' }} onClick={openGasSettings}> <GasIcon height={16} width={16} /></small>
        </RowFixed>
        <RowFixed>
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  )
}
