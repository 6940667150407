import { useCallback } from 'react'
import { useActiveWeb3React } from 'hooks/web3'
import { useIsUserManuallyDisconnect } from 'state/user/hooks'
import { injected } from 'connectors'

const useDisconnectWallet = () => {
  const [, setIsUserManuallyDisconnect] = useIsUserManuallyDisconnect()
  const { connector, deactivate } = useActiveWeb3React()
  return useCallback(() => {
    console.log(connector);

    if (connector === injected) {
      deactivate();
    } else {
      (connector as any).close();
    }
    setIsUserManuallyDisconnect(true)
  }, [connector, deactivate, setIsUserManuallyDisconnect,])
}
export default useDisconnectWallet
